<template>
  <div class="container">
    <div class="classify_title">
      <div class="classify_title_item" @click="changeClassify(1)">
        <img
          :src="
            activeIndex == 1
              ? require('@/assets/images/myVideo_active@2x.png')
              : require('@/assets/images/myVideo@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 1 }"
          >视频观看</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 1 }"
          >{{ namelist.VideoCount }}</span
        >
      </div>
      <div class="classify_title_item" @click="changeClassify(2)">
        <img
          :src="
            activeIndex == 2
              ? require('@/assets/images/myImage_active@2x.png')
              : require('@/assets/images/myImage@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 2 }"
          >图文阅读</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 2 }"
          >{{ namelist.StickersCount }}</span
        >
      </div>
      <div class="classify_title_item" @click="changeClassify(3)">
        <img
          :src="
            activeIndex == 3
              ? require('@/assets/images/myMeeting_active@2x.png')
              : require('@/assets/images/myMeeting@2x.png')
          "
        />
        <span class="classify_title_text" :class="{ active: activeIndex == 3 }"
          >会议相关</span
        >
        <span
          class="classify_title_num"
          :class="{ active: activeIndex == 3 }"
          >{{ namelist.LiveCount }}</span
        >
      </div>
    </div>
    <div class="content">
      <div class="tips">
        <span>仅保留3个月内浏览记录</span>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了~"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div class="block">
          <!-- <p class="date">2022.03.18</p> -->
          <div class="listBox">
            <div class="list" v-for="(item, index) in list" :key="index">
              <a @click="$router.push('/contentPage?id=' + item.Id)">
                <div class="item_img">
                  <img class="item_cover" :src="item.Thumbnail" />
                  <img
                    v-if="item.Type == 1"
                    class="playIcon"
                    src="@/assets/images/play_comm_icon@2x.png"
                  />
                </div>
                <div class="item_content">
                  <span class="item_title">{{ item.Title }}</span>
                  <div class="item_label">
                    <span
                      v-for="(item, index) in item.TagListBy"
                      :key="index"
                      >{{ item.DisplayName }}</span
                    >
                  </div>
                  <div class="item_info">
                    <span class="item_time">{{ item.yyyymmdd }}</span>
                    <div class="item_nums">
                      <div class="nums">
                        <img src="@/assets/images/eye@2x.png" />
                        <span>{{ item.WatchCount }}</span>
                      </div>
                      <div class="nums">
                        <img src="@/assets/images/likes_ash@2x.png" />
                        <span>{{ item.LikeCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BrowseRecords",
  data() {
    return {
      form: {
        customerid: "",
        atype: 1,
        btype: 1,
      },
      activeIndex: 1,
      list: [],
      namelist: [],
      listby: [],
      loading: false,
      finished: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.name();
  },
  methods: {
    changeClassify(val) {
      this.activeIndex = val;
      this.onLoad();
      this.name();
    },
    onLoad() {
      this.form.customerid = this.userInfo.Id;
      console.log(
        "cid" +
          this.form.customerid +
          "atype" +
          this.form.atype +
          "btype" +
          this.activeIndex
      );
      let that = this;
      that.loading = true;
      that.form.btype = this.activeIndex;
      that.$axios
        .post("/Api/Api/Web/GetArticle_Behavior/GetBaList", that.form)
        .then((res) => {
          console.log("123", res);
          that.list = res.Data;
          that.loading = false;
        });
      this.loading = false;
    },

    name() {
      this.form.customerid = this.userInfo.Id;
      let that = this;
      that.list = [];
      that.loading = true;
      that.form.utype = this.activeIndex;
      that.url = "/Api/Api/Web/GetArticle_Behavior/GetUserBeh";
      that.$axios
        .post(
          "/Api/Api/Web/GetArticle_Behavior/GetUserBeh?CustomerID=" +
            that.form.customerid +
            "&BehaviorType=1"
        )
        .then((res) => {
          // console.log("cid" + this.form.customerid + "utype" + this.form.utype);
          console.log("999", res);
          that.namelist = res.Data;
          that.loading = false;
        });

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  .classify_title {
    display: flex;
    background-color: rgba(216, 216, 216, 0.2);
    .classify_title_item {
      flex: 1;
      text-align: center;
      padding: 12px 0;
      overflow: hidden;
      img {
        width: 25px;
        height: 23px;
        display: block;
        margin: 0 auto;
      }
      span {
        display: block;
        color: #000000;
      }
      .classify_title_text {
        font-size: 14px;
        margin: 6px 0;
      }
      .classify_title_num {
        font-size: 15px;
      }
      .active {
        font-size: 15px;
        color: #ca001b;
        font-weight: bold;
      }
    }
  }
  .content {
    flex: 1;
    height: 0;
    overflow-y: scroll;
    padding: 20px 12px;
    box-sizing: border-box;
    .tips {
      position: relative;
      text-align: center;
      margin-bottom: 20px;
      span {
        position: relative;
        z-index: 9;
        display: inline-block;
        padding: 0 4px;
        font-size: 9px;
        color: #999999;
        background-color: #ffffff;
      }
    }
    .tips::after {
      content: "";
      width: 60%;
      height: 1px;
      background-color: #979797;
      position: absolute;
      top: 50%;
      left: 20%;
    }
    .block {
      .date {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        margin: 0 0 10px 5px;
      }
      .listBox {
        display: flex;
        flex-wrap: wrap;
      }
      .list {
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(calc(100% / 2) - 10px);
        margin: 0 5px 10px;
        background-color: #ffffff;
        border-radius: 7px;
        border: 1px solid rgba(151, 151, 151, 0.15);
        box-sizing: border-box;
        overflow: hidden;
        .item_img {
          position: relative;
          height: 90px;
          overflow: hidden;
          .item_cover {
            display: block;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .playIcon {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 23px;
            height: 23px;
          }
        }
        .item_content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          padding: 8px;
          .item_title {
            font-size: 13px;
            color: #000000;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-wrap: break-word;
            margin-bottom: 8px;
            height: 1rem;
          }
          .item_label {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            span {
              display: inline-block;
              height: 20px;
              line-height: 20px;
              border-radius: 2px;
              padding: 0 6px;
              margin-right: 6px;
              margin-bottom: 5px;
              background-color: rgba(79, 120, 234, 0.1);
              font-size: 9px;
              color: #262246;
            }
          }
          .item_info {
            display: flex;
            justify-items: center;
            justify-content: space-between;
            color: #a0a2a5;
            margin-top: 5px;
            .item_time {
              font-size: 9px;
              line-height: 18px;
            }
            .item_nums {
              display: flex;
              justify-items: center;
              .nums {
                display: flex;
                align-items: center;
                img {
                  width: 12px;
                  margin: 0 2px 0 8px;
                }
                span {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>